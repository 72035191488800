import styled, { keyframes } from 'styled-components';
import { Link as LinkR } from 'react-router-dom'
// import background from "../../images/PEP_BG.png"
import clouds from "../../images/clouds.png"


export const MintPageContainer = styled.div`
z-index: 2;
min-width: 100vw;

    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position:absolute;
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding: 0px;
    padding-top: 120px;
`;

export const MintPageNavbar = styled.div`
padding: 12px;
padding-right: 60%;
padding-left: 10px;
padding-bottom: 0;
position: relative;
display: flex;
z-index: 2;
`;

export const NavbarLinkA = styled.a`
padding: 5px;
align-items: left;
cursor: pointer;
display: left;
z-index: 2;
`;

export const NavbarImage = styled.img`
height: 50px;
`;

export const MintPanelImage = styled.img`
max-width: 100%;
`;

export const MintPageMain = styled.div`
display: inline-block;
float: bottom;
justify-content: center;
text-align: center;
margin-top: auto;
`;

export const MintLabel = styled.label`
z-index: 2;
margin-bottom: 10px;
font-size: 28px;
color: #60c657;
text-shadow: 0 0 15px #60c657;
`;

export const InputWrapper = styled.div`
display: flex;
flex-direction: row;
`;

export const MintInput = styled.input`
color: #60c657;
border-radius: 25px;
justify-content: center;
justify-self: center;
background: none;
border: 1px solid #60c657;
text-align: center;
font-size: 28px;
margin-bottom: 10px;
z-index: 2;
::-webkit-inner-spin-button{
  -webkit-appearance: none; 
  margin: 0; 
}
::-webkit-outer-spin-button{
  -webkit-appearance: none; 
  margin: 0; 
}   
`;

export const InputArrows = styled.label`
text-align: center;
font-size: 28px;
padding: 0 10px;
color: #60c657;
cursor: pointer;
z-index: 2;
`

export const MintButton = styled.button`
z-index: 2;
border-radius: 50px;
background: #FFF;
white-space: nowrap;
text-decoration: none;
cursor: pointer;
color: #000;
border: none;
padding: 10px 22px;
font-size: auto;
font-weight: 700;
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
box-shadow: 0 2px 2px rgba(0, 0, 0, 0.9);
width: 100px;
`;

export const DescriptionPanel = styled.div`
display: inline-block;
float: top;
align-items: center;
text-align: center;
justify-content: center;
`;

export const AmountMinted = styled.h1`
color: #fff;
text-shadow: 2px 2px rgba(0, 0, 0, 0.9);
margin-bottom: 5px;
margin-top: 5px;
padding: 2px;
font-size: 24px;
}
`;

export const MintPanel = styled.div`
display: flex;
flex-direction: column-reverse;
text-align: center;
margin: 40px 0;
padding-bottom: 50%;
min-height: 30vh;
z-index: 2;
`;

export const GridCol = styled.div`
display: flex;
align-items: center;
justify-self: center;
justify-content: center;
flex-direction: column;
margin-left: 10px;
margin-right: 10px;
`;

export const TextWrapper = styled.div`
color: #fff;
text-shadow: 2px 2px rgba(0, 0, 0, 0.9);
padding: 10px;
max-width: 800px;
justify-content: center;

`;

export const Title = styled.h1`
margin: 10px 0;
color: #6495ED;
text-shadow: 0 0 15px #6495ED;
font-size: 50px;
`;

export const Connect = styled.h1`
z-index: 2;
margin-bottom: 10px;
color: #60c657;
text-shadow: 0 0 15px #60c657;
font-size: 50px;
padding-right:22%;
cursor: pointer;
&:hover {
  text-shadow: 0 0 20px #60c657;
}

`;

export const Links = styled.h1`
z-index: 3;
margin-bottom: 10px;
padding-top:280px;
color: #60c657;
text-shadow: 0 0 15px orange;
font-size: 50px;
padding-right:80%;
cursor: pointer;
&:hover {
  text-shadow: 0 0 20px #60c657;
}

`;

export const Links2 = styled.h1`
z-index: 2;
margin-bottom: 10px;
background-color:transparent;
padding-top:50px;
color: #60c657;
text-shadow: 0 0 15px orange;
font-size: 50px;
padding-right:41%;
cursor: pointer;
&:hover {
  text-shadow: 0 0 20px #60c657;
}

`;

export const Subtitle = styled.h3`
margin-bottom: 10px;
color: #fff;
text-shadow: 2px 2px rgba(0, 0, 0, 0.9);
`;

export const Logo = styled.img`
align-self: center;
width: 100%;
max-width: 750px;
margin: 20px 0;
padding: 0 10px;
z-index: 2;
`;

export const Title2 = styled.h1`

  --neon-text-color: #f40;
  --neon-border-color: #08f;

  width: 100%;
  background-color: transparent;
  position: relative;
  font-size: 50px;
  font-weight: 200;
  font-style: italic;
  color: #fff;
  padding: 10px 10px 5.5px;
  border: 0.4rem solid #fff;
  radius: 30%;
  text-transform: uppercase;
  animation: flicker 1.5s infinite alternate;



::-moz-selection {
  background-color: transparent;
  color: var(--neon-text-color);
}

::selection {
  background-color: transparent;
  color: var(--neon-text-color);
}

:focus {
  outline: none;
}

/* Animate neon flicker */
@keyframes flicker {
    
    0%, 15%, 21%, 23%, 25%, 55%, 56%, 100% {
      
        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);
        
        box-shadow:
            0 0 .5rem #fff,
            inset 0 0 .5rem #fff,
            0 0 2rem var(--neon-border-color),
            inset 0 0 2rem var(--neon-border-color),
            0 0 4rem var(--neon-border-color),
            inset 0 0 4rem var(--neon-border-color);        
    }
    
    10%, 20%, 45% {        
        text-shadow: none;
        box-shadow: none;
    }    
`;

export const Title3 = styled.h1`

  --neon-text-color: orange;
  --neon-border-color: #c53acb;

  width: 100%;
  background-color: transparent;
  position: relative;
  padding-right:41%;

  font-size: 40px;
  font-weight: 200;
  font-style: italic;
  color: #fff;
  padding: 10px 10px 5.5px;
  border: 0.4rem solid #fff;
  radius: 30%;
  text-transform: uppercase;
  animation: flicker 1.5s infinite alternate;



::-moz-selection {
  background-color: transparent;
  color: var(--neon-text-color);
}

::selection {
  background-color: transparent;
  color: var(--neon-text-color);
}

:focus {
  outline: none;
}

/* Animate neon flicker */
@keyframes flicker {
    
    0%, 15%, 21%, 23%, 25%, 55%, 56%, 100% {
      
        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);
        
        box-shadow:
            0 0 .5rem #fff,
            inset 0 0 .5rem #fff,
            0 0 2rem var(--neon-border-color),
            inset 0 0 2rem var(--neon-border-color),
            0 0 4rem var(--neon-border-color),
            inset 0 0 4rem var(--neon-border-color);        
    }
    
    10%, 20%, 45% {        
        text-shadow: none;
        box-shadow: none;
    }    
`;

export const Title4 = styled.h1`

  --neon-text-color: #90b7c7;
  --neon-border-color: #1da2be;

  width: 100%;
  background-color: transparent;
  position: relative;
  padding-right:21%;

  font-size: 40px;
  font-weight: 200;
  font-style: italic;
  color: white;
  padding: 10px 10px 5.5px;
  border: 0.4rem solid #90b7c7;
  radius: 30%;
  text-transform: uppercase;
  animation: flicker 1.5s infinite alternate;



::-moz-selection {
  background-color: transparent;
  color: var(--neon-text-color);
}

::selection {
  background-color: transparent;
  color: var(--neon-text-color);
}

:focus {
  outline: none;
}

/* Animate neon flicker */
@keyframes flicker {
    
    0%, 15%, 21%, 23%, 25%, 55%, 56%, 100% {
      
        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);
        
        box-shadow:
            0 0 .5rem #fff,
            inset 0 0 .5rem #fff,
            0 0 2rem var(--neon-border-color),
            inset 0 0 2rem var(--neon-border-color),
            0 0 4rem var(--neon-border-color),
            inset 0 0 4rem var(--neon-border-color);        
    }
    
    10%, 20%, 45% {        
        text-shadow: none;
        box-shadow: none;
    }    
`;

export const Form = styled.h1`

  --neon-text-color: green;
  --neon-border-color: green;
  
  width: 100%;
  background-color: transparent;
  height:80%;
  position: relative;
  font-size: 50px;
  font-weight: 200;
  font-style: italic;
  color: #fff;
  padding: 10px 10px 5.5px;
  border: 0.4rem solid #fff;
  radius: 30%;
  text-transform: uppercase;
  



::-moz-selection {
  background-color: transparent;
  color: var(--neon-text-color);
}

::selection {
  background-color: transparent;
  color: var(--neon-text-color);
}

:focus {
  outline: none;
}

/* Animate neon flicker */
@keyframes flicker {
    
    0%, 15%, 21%, 23%, 25%, 55%, 56%, 100% {
      
        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);
        
        box-shadow:
            0 0 .5rem #fff,
            inset 0 0 .5rem #fff,
            0 0 2rem var(--neon-border-color),
            inset 0 0 2rem var(--neon-border-color),
            0 0 4rem var(--neon-border-color),
            inset 0 0 4rem var(--neon-border-color);        
    }
    
    10%, 20%, 45% {        
        text-shadow: none;
        box-shadow: none;
    }    
`;

export const FooterLinksWrapper = styled.div`
padding: 10px;
margin: 10px;
display: flex;
justify-content: center;
z-index: 2;
@media screen and (max-width: 539px) {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
`;

export const FooterLinks = styled.a`
border-radius: 50px;
background: #FFF;
white-space: nowrap;
text-decoration: none;
cursor: pointer;
color: #000;
padding: 10px 22px;
font-size: auto;
font-weight: 700;
margin: 10px;
z-index: 2;

text-align: center;
box-shadow: 0 2px 2px rgba(0, 0, 0, 0.9);
`;

function scrollEffect() {
  return keyframes`
  100% {
    right: 0px;
  }
  50% {
    right: 20%;
  }
  0% {
    right: 0px;
  }
  `;
}

export const Clouds = styled.img`
position: absolute;
z-index: 1;
height: 100%;
object-fit: contain;
animation: ${scrollEffect} 90s linear infinite;
`;
