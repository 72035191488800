import React, {useState, useEffect, useRef} from "react";
import './Navbar.css'
import {useNavigate} from 'react-router-dom'
// images
import pepsicoLabsLogo from '../../images/PEP_LOGO.png.webp';
import pepsicoDemandAccelLogo from '../../images/PEP_LOGO2.png';



const AccountButton = (props) => {
    if(props.appState.accounts) {
        if(props.appState.user) {
            return (
                <div className="navbar-account-button">
                    <div className="navbar-account-circle">
                        <p>
                            {props.appState.user.firstname ?
                               `${props.appState.user.firstname.charAt(0)}${ props.appState.user.lastname.charAt(0) ? props.appState.user.lastname.charAt(0) : ''}`
                            : 'Hi'}
                        </p>
                    </div>
                    <p className="navbar-account-text">
                        {props.appState.user.firstname ?
                            `${props.appState.user.firstname} ${ props.appState.user.lastname ? props.appState.user.lastname : ''}`
                        : 'Connected'}
                    </p>
                </div>
            );
        } else {
            return (
                <div className="navbar-account-button">
                    <p className="navbar-account-text">
                        {props.appState.firstname}
                    </p>
                </div>
            )
        }
    }  else {
        return null
    }
}


const Navbar = (props) => {
    const [goingUp, setGoingUp] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const prevScrollY = useRef(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (prevScrollY.current < currentScrollY && goingUp) {
                setGoingUp(false);
            }
            if (prevScrollY.current > currentScrollY && !goingUp) {
                setGoingUp(true);
            }
            setScrollY(currentScrollY);

            prevScrollY.current = currentScrollY;
            // console.log(goingUp, currentScrollY);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => window.removeEventListener("scroll", handleScroll);
    }, [goingUp]);



    return (
        <div style={scrollY > 10 ? {backgroundColor: 'black'} : null} className="navbar">
            <div className="navbar-logos">
                <div>
                    <img src={pepsicoLabsLogo} alt="PepsiCo Labs logo" className="navbar-logo" />
                </div>
                <div>
                    <img src={pepsicoDemandAccelLogo} alt="PepsiCo Demand Accelerator logo" className="navbar-logo-two" />
                </div>
            </div>
            <div className="navbar-account">
                <AccountButton
                    appState={props.appState}
                    startWalletConnection={props.startWalletConnection}
                />
            </div>
        </div>
    )
}

export default Navbar;
