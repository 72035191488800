import React from "react";
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <p className={'footer-text'}>Ⓒ PepsiCo 2022</p>
            {/*<a className={'footer-link'} href={'https://www.pepsico.com/legal/terms-of-use'}>Terms of Use</a>*/}
            {/*<a className={'footer-link'} href={'https://www.pepsico.com/legal/privacy'}>Privacy Policy</a>*/}
        </div>
    );
}

export default Footer;