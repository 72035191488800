import React, {useEffect} from 'react';
import {useParams, useNavigate, Navigate} from "react-router-dom";
import {Title2, Title4} from '../components/Mint/MintingPageElements.js'
import '../styles/pages/product.css';
import {useForm} from "react-hook-form";

const Product = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    console.log('errors', errors);
    let { productId } = useParams(); // get productId from url
    const navigate = useNavigate();

    // scroll to top of page on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        // set the productId in the state to claim
        props.setProduct(productId);

        // set productId to null when leaving page
        return () => {
            props.setProduct(null);
        }
    },[])


    // handle form submit button
    const onSubmit = (data) => {

        // onSubmit shouldn't get called if there are errors but just to be safe
        // if there are no errors, then submit the form
        if(Object.keys(errors).length === 0) {
            console.log('no errors');
            console.log('onSubmit', data)
            // call claim function in App.js
            props.doClaim(data);
        }
    };


    // if(!props.appState.accounts || !props.appState.user || !props.appState.termsAccepted || props.appState.userNotInDatabase || !props.appState.poap) {
    //     return <Navigate to="/"/>
    // }


    return (
       <div className='product-page'>
           <div className='product-page-inner'>

               <div className='product-page-header'>
                   <div>
                          <img src={ props.appState.activities.find(a => a.id === parseInt(productId))?.nftImage } alt='product' />
                   </div>
                   <div>
                       <h1>Awesome!</h1>
                       <p>You just got a { props.appState.activities.find(a => a.id === parseInt(productId))?.nftName }!</p>
                       <p>Fill out the information below to claim your gift.</p>
                   </div>
               </div>


               <form className="product-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                   {/* ============= EMAIL ADDRESS =============*/}
                   <label className="custom-input-label" htmlFor="email">Email</label>
                   <input
                       // className={"custom-input" + (errors.email && " custom-input-error")}
                       className={`custom-input ${errors.email ? " custom-input-error" : "" }`}
                       id="email"
                       placeholder="Enter your email"
                       aria-invalid={errors.email ? "true" : "false"}
                       {...register('email', { required: true, pattern: /^\S+@\S+$/i})}
                       autoComplete="new-password"
                   />
                   {/* NO EMAIL ERROR*/}
                   {errors.email && errors.email.type === "required" && (
                       <div className="error-input-alert">
                            <p><span>⚠</span> Email is required. Please fill out the missing field then hit submit.</p>
                       </div>
                   )}
                   {/* INVALID EMAIL PATTERN ERROR*/}
                   {errors.email && errors.email.type === "pattern" && (
                       <div className="error-input-alert">
                           <p><span>⚠</span> Please enter a valid email</p>
                       </div>
                   )}
                   {/* ============= EMAIL ADDRESS =============*/}


                   {/* ============= ADDRESS LINE 1 =============*/}
                   <label className="custom-input-label" htmlFor="email">Address 1</label>
                   <input
                       // className="custom-input"
                       className={`custom-input ${errors.address1 ? " custom-input-error" : "" }`}
                       id="address1"
                       placeholder="Enter your address line 1"
                       aria-invalid={errors.address1 ? "true" : "false"}
                       autoComplete="new-password"
                       {...register('address1')}
                   />
                   {/* NO ADDRESS LINE 1 error*/}
                   {errors.address1 && errors.address1.type === "required" && (
                       <div className="error-input-alert">
                           <p><span>⚠</span> Address line 1 required. Please fill out the missing field then hit submit.</p>
                       </div>
                   )}
                   {/* ============= ADDRESS LINE 1 =============*/}

                   {/* ============= ADDRESS LINE 2 =============*/}
                   <label className="custom-input-label" htmlFor="email">Address 2</label>
                   <input
                       className="custom-input"
                       id="address1"
                       placeholder="Enter your address line 2"
                       // aria-invalid={errors.address2 ? "true" : "false"}
                       autoComplete="new-password"
                       {...register('address2')}
                   />
                   {/* ============= ADDRESS LINE 2 =============*/}


                   {/* ============= CITY =============*/}
                   <label className="custom-input-label" htmlFor="email">City</label>
                   <input
                       // className="custom-input"
                       className={`custom-input ${errors.city ? " custom-input-error" : "" }`}
                       id="city"
                       placeholder="City"
                       aria-invalid={errors.city ? "true" : "false"}
                       autoComplete="new-password"
                       {...register('city')}
                   />
                   {/* NO CITY ERROR*/}
                   {errors.city && errors.city.type === "required" && (
                       <div className="error-input-alert">
                           <p><span>⚠</span> City required. Please fill out the missing field then hit submit.</p>
                       </div>
                   )}
                   {/* ============= CITY =============*/}


                   {/* ============= STATE =============*/}
                   <label className="custom-input-label" htmlFor="email">State</label>
                   <input
                       // className="custom-input"
                       className={`custom-input ${errors.state ? " custom-input-error" : "" }`}
                       id="city"
                       placeholder="State"
                       aria-invalid={errors.state ? "true" : "false"}
                       autoComplete="new-password"
                       {...register('state')}
                   />
                   {/* NO ADDRESS LINE 1 ERROR*/}
                   {errors.state && errors.state.type === "required" && (
                       <div className="error-input-alert">
                           <p><span>⚠</span> State required. Please fill out the missing field then hit submit.</p>
                       </div>
                   )}
                   {/* ============= STATE =============*/}


                   {/* ============= ZIP/POSTCODE =============*/}
                   <label className="custom-input-label" htmlFor="email">ZIP/Postcode</label>
                   <input
                       // className="custom-input"
                       className={`custom-input ${errors.postcode ? " custom-input-error" : "" }`}
                       id="city"
                       placeholder="ZIP/Postcode"
                       aria-invalid={errors.postcode ? "true" : "false"}
                       autoComplete="new-password"
                       {...register('postcode')}
                   />
                   {/* NO ZIPCODE ERROR*/}
                   {errors.postcode && errors.postcode.type === "required" && (
                       <div className="error-input-alert">
                           <p><span>⚠</span> ZIP/Postcode required. Please fill out the missing field then hit submit.</p>
                       </div>
                   )}
                   {/* ============= ZIP/POSTCODE =============*/}

                   {/* ============= COUNTRY =============*/}
                   <label className="custom-input-label" htmlFor="email">Country</label>
                   <input
                       // className="custom-input"
                       className={`custom-input ${errors.country ? " custom-input-error" : "" }`}
                       id="city"
                       placeholder="Country"
                       aria-invalid={errors.country ? "true" : "false"}
                       autoComplete="new-password"
                       {...register('country')}
                   />
                   {/* NO COUNTRY ERROR*/}
                   {errors.country && errors.country.type === "required" && (
                       <div className="error-input-alert">
                           <p><span>⚠</span> Country required. Please fill out the missing field then hit submit.</p>
                       </div>
                   )}
                   {/* ============= COUNTRY =============*/}

                   {/*<input style={{marginTop: '20px'}} type="submit" value="submit" />*/}
                   <div className='product-page-buttons-container'>
                       <button className="submit-button" type="submit" value="submit">Submit</button>
                       <button className="cancel-button" onClick={() => navigate('/')}>Cancel</button>
                   </div>
               </form>
           </div>
       </div>
    );
}
export default Product;