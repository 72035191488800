import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

//pages
// import Mint from './pages/mint.js'
import Main from './pages/main.js';
import Home from './pages/home.js';
import Product from './pages/product.js';
import NotFoundPage from "./pages/notFoundPage.js";

// components
import Navbar from './components/Navbar/Navbar.js';
import Footer from './components/Footer/Footer.js';

// modals
import CongratsModal from "./components/modals/CongratsModal";
import NotFoundModal from "./components/modals/NotFoundModal";
import AcceptTermsModal from "./components/modals/AcceptTermsModal";

//images
import appBackground from './images/PEP_BG.png';
import purpleDotBackground from './images/purple-dot-background.jpeg';
import Web3 from "web3";
import beachDayImage from "./images/beachDayImage.jpeg";
import quietMorningTimeImage from "./images/quietMorningTimeImage.jpeg";
import nightWithFriendsImage from "./images/nightWithFriendsImage.jpeg";
import outdoorFamilyFunImage from "./images/outdoorFamilyFunImage.jpeg";
import uninterruptedGamePlayImage from "./images/uninterruptedGamePlayImage.jpeg";
import pepsiBlanket from "./images/Pepsi_BLANKET_v1.png";
import pepsiMug from "./images/Pepsi_MUG_v1.png";
import pepsiTowel from "./images/Pepsi_TOWEL_v1.png";
import pepsiTumbler from "./images/Pepsi_TUMBLER_v1.png";
import pepsiCharger from "./images/Pepsi_CHARGER_v1.png";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            web3: null,
            accounts: null,
            contract: window.contract,
            sig: null,
            api: window.api,
            msg: window.message,
            poap: null,
            user: null,
            email: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            postcode: null,
            country: null,
            product: null,
            getUpdatesSelected: false,
            claimed: null,
            showNotFoundModal: false,
            showCongratsModal: false,
            checkedIn: false,
            shouldDisplayWelcomeBack: false,
            claimClosed: window.claimclosed,
            userNotInDatabase: false,
            termsAccepted: false,
            activities: [
                {id: 1, name: "Beach day", image: beachDayImage, nftImage: pepsiTowel, nftName: "beach towel"},
                {id: 2, name: "Quiet morning", image: quietMorningTimeImage, nftImage: pepsiMug, nftName: "mug"},
                {id: 3, name: "Night with friends", image: nightWithFriendsImage, nftImage: pepsiTumbler, nftName: "tumbler"},
                {id: 4, name: "Outdoor family fun", image: outdoorFamilyFunImage, nftImage: pepsiBlanket, nftName: "blanket"},
                {id: 5, name: "Uninterrupted game play", image: uninterruptedGamePlayImage, nftImage: pepsiCharger, nftName: "charger"}
            ],
        };
    }


    async componentDidMount() {

        try {
            if (window.ethereum) {
                // setup for interactions with web3
                const web3 = new Web3(window.ethereum);
                this.setState({ web3 });
            }
        } catch (error) {
            console.error(error);
        }
    }

    // load items from local storage
   /* loadLocalStorageItems = () => {
        const accounts = JSON.parse(localStorage.getItem('accounts'));
        const poap = JSON.parse(localStorage.getItem('poap'));
        const claimed = JSON.parse(localStorage.getItem('claimed'));
        const user = JSON.parse(localStorage.getItem('user'));
        this.setState({
            accounts: accounts,
            poap: poap,
            claimed: claimed,
            user: user,
        });

        // if user has already connected, show welcome back
        if (user) {
            this.setState({
                shouldDisplayWelcomeBack: true,
            });
        }


    }
*/

    doWalletConnect = async () => {
        const { web3, contract, poap, sig, api, msg, user, claimed } = this.state;
        try {
            await window.ethereum.enable();
            const accounts = await web3.eth.getAccounts();

            const instance = new web3.eth.Contract(
                [{"inputs":[{"internalType": "address","name": "owner","type": "address"}],"name": "balanceOf","outputs": [{"internalType": "uint256","name": "","type": "uint256"}],"stateMutability": "view","type": "function"}],
                contract,
            );

            const nftCount = await instance.methods.balanceOf(accounts[0]).call()
            console.log('balanceOf :: ', nftCount);
            const hasNft = nftCount > 0 ? true : false;

            if (hasNft) {
                console.log('hasNft')
                try {
                    var url = api + 'api/user/';
                    var signature = await web3.eth.personal.sign(msg, accounts[0])

                    console.log(accounts[0])
                    console.log(msg)
                    console.log(signature)

                    const options = {
                        method: 'POST',
                        body: JSON.stringify({ message: msg, wallet: accounts[0], signature: signature })
                    };

                    const response = await fetch(url, options)
                    const json = await response.json()
                    console.log(json)

                    this.setState({sig: signature, user: json, claimed: json.product})
                } catch (error) {
                    console.log(error);
                }
            }

            this.setState({ web3, accounts, poap: hasNft });
        } catch (error) {
            alert('Failed to load web3 or accounts.');
            console.error(error);
        }
    };

    // promp connection to wallet when users clicks connect button
    startWalletConnection = async () => {
        console.log('startWalletConnection');
        // const { web3 } = this.state;
        try {
            const { web3, contract } = this.state;
            // console.log('web3', web3);
            // console.log('contract', contract);
            await window.ethereum.enable();
            // returns Array - An array of addresses controlled by node.
            const accounts = await web3.eth.getAccounts();
            // console.log('accounts', accounts);
            const instance = new web3.eth.Contract(
                [{"inputs":[{"internalType": "address","name": "owner","type": "address"}],"name": "balanceOf","outputs": [{"internalType": "uint256","name": "","type": "uint256"}],"stateMutability": "view","type": "function"}],
                contract,
            );
            // console.log('instance', instance);
            const nftCount = await instance.methods.balanceOf(accounts[0]).call();
            // console.log('nftCount', nftCount);
            // console.log('balanceOf :: ', nftCount);
            const hasNft = nftCount > 0 ? true : false;

            if(hasNft) {
                this.setState({poap: hasNft});
            } else{
                this.setState({showNotFoundModal: true, poap: false})
            }
            //localStorage.setItem('poap', JSON.stringify(hasNft));
            //localStorage.setItem('accounts', JSON.stringify(accounts));
            this.setState({ web3, accounts });
        } catch (error) {
            alert('Please switch your network to Ethereum Mainnet with MetaMask.');
            // console.error(error);
            console.log('error', error);
        }
    };

    // pull user data based on user address
    doCheckIn = async () => {
        const { web3, accounts, sig, api, msg, user } = this.state;
        try {
            var url = api + '/api/user/';
            var signature = await web3.eth.personal.sign(msg, accounts[0])

            // console.log(accounts[0])
            // console.log(msg)
            // console.log(msg)

            const options = {
                method: 'POST',
                body: JSON.stringify({ message: msg, wallet: accounts[0], signature: signature })
            };

            const response = await fetch(url,options)
            const json = await response.json();

            //localStorage.setItem('user', JSON.stringify(json));

            if(json.product) {
                this.setState({claimed: true})
               // localStorage.setItem('claimed', JSON.stringify(true));
            }

            this.setState({
                sig: signature,
                user: json,
                checkedIn: true
            });


            if(json.success === false) {
                // console.log('USER HAS NFT BUT NOT IN DB');
                this.setState({userNotInDatabase: true});
            } else {
                // console.log('USER HAS AND IS IN DB');
                this.setState({userNotInDatabase: false})
                // scroll to bottom of page
                setTimeout(() => {
                    window.scrollTo(0, 7000);
                }, 200);
            }

        } catch (error) {
            console.log('error', error);
        }
    };

    // claim nft
    doClaim = async (formData) => {
        // setState with date from form
        // this.setState({
        //     email: formData.email,
        //     address1: formData.address1,
        //     address2: formData.address2,
        //     city: formData.city,
        //     state: formData. state,
        //     postcode: formData.postcode,
        //     country: formData.country,
        // });

        const { accounts, sig, api, msg, email, address1, address2, city, state, postcode, country, product, user } = this.state;

        try {
            var url = api + 'api/claim/';

            const options = {
                method: 'POST',
                body: JSON.stringify(
                    {
                        firstname: user?.firstname,
                        lastname: user?.lastname,
                        message: msg,
                        wallet: accounts[0],
                        signature: sig,
                        // email: email,
                        // address1: address1,
                        // address2: address2,
                        // city: city,
                        // state: state,
                        // postcode: postcode,
                        // country: country,
                        email: formData.email,
                        address1: formData.address1,
                        address2: formData.address2,
                        city: formData.city,
                        state: formData. state,
                        postcode: formData.postcode,
                        country: formData.country,
                        product: product
                    }
                )
            };
            // console.log('product', product);

            const response = await fetch(url,options)
            const json = await response.json()
            // console.log(json)
            if (json.success) {
                this.setState({claimed: true});
               // localStorage.setItem('claimed', JSON.stringify(true));
                this.setState({showCongratsModal: true});
            } else {
                alert(json.message);
            }

        } catch (error) {
            console.log(error);
        }
    };


    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    handleCheckboxChange = () => {
        this.setState({
            getUpdatesSelected: !this.state.getUpdatesSelected
        });
    }

    setProduct = (productId) => {
        console.log('setProduct', productId);
        this.setState({product: productId})
    }


    // toggle congrats modal visibility
    toggleCongratsModal = (isOpen) => {
        this.setState({showCongratsModal: isOpen})
    }

    // toggle not found modal visibility
    toggleNotFoundModal = (isOpen) => {
        window.scrollTo(0, 7000);
        this.setState({showNotFoundModal: isOpen})
    }

    acceptTerms = () => {
        this.setState({termsAccepted: true})
    }


  render() {
        return (
         <Router>
          <div style={{ backgroundImage: `url(${purpleDotBackground})` }} className="App">
            <Navbar appState={this.state} startWalletConnection={this.startWalletConnection}/>
            <div className="app-content">

                  <Routes>
                    <Route exact path="/" element={<Home
                                                        appState={this.state}
                                                        doWalletConnect={this.doWalletConnect}
                                                        startWalletConnection={this.startWalletConnection}
                                                        doCheckIn={this.doCheckIn}
                                                        doClaim={this.doClaim}
                                                        // activities={this.state.activities}
                                                      />}
                    />
                    <Route exact path="/product/:productId" element={<Product
                                                                        appState={this.state}
                                                                        doClaim={this.doClaim}
                                                                        // activities={this.state.activities}
                                                                        handleChange={this.handleChange}
                                                                        setProduct={this.setProduct}
                                                                        handleCheckboxChange={this.handleCheckboxChange}
                                                                     />}
                    />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>

            </div>
            <Footer />

              {this.state.showCongratsModal &&
                  <CongratsModal
                      toggleCongratsModal={this.toggleCongratsModal}
                  />
              }

              {this.state.showNotFoundModal &&
                    <NotFoundModal
                        toggleNotFoundModal={this.toggleNotFoundModal}
                    />
              }


              {!this.state.termsAccepted &&
                  <AcceptTermsModal
                      acceptTerms={this.acceptTerms}
                  />
              }

          </div>
          </Router>
        );
  }
}

export default App;
