import React, { Component, useEffect } from 'react';

// Components
import ChooseActivitySection from '../components/ChooseActivity/ChooseActivitySection.js';
import HomepageHeader from "../components/HomepageHeader/HomepageHeader";

import nftBg from '../images/nftBg.png';


const Home = (props) => {
    // console.log('claimed on home page', props.appState.claimed);

    useEffect(() => {
        window.scrollTo(0, 0);
    } , []);

    return (
        <div className='home-page'>
            <HomepageHeader
                appState={props.appState}
                startWalletConnection={props.startWalletConnection}
                doCheckIn={props.doCheckIn}
            />
            <ChooseActivitySection
                appState={props.appState}
            />
        </div>
    );
}

export default Home;
