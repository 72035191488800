import {useEffect} from "react";
import './modal.css';
import web3KickoffNft from '../../images/web3kickoff-nft-image.jpeg';
import {useNavigate} from "react-router-dom";

const AcceptTermsModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // const close = () => {
    //     props.toggleNotFoundModal(false);
    //     // navigate('/');
    // }

    return(
        <div className='accept-terms-modal-container'>
            <div style={{paddingTop: '60px'}} className='modal'>
                <p style={{fontSize: '14px'}} className={"p-margin-bottom"}>Please note: By accepting the physical merchandise described, you agree that the non-fungible token (the “NFT”) provided to you shall have no further value or utility.  You agree that the NFT shall be used by you only for your personal enjoyment only and in no event shall you sell, transfer or commercially exploit the NFT without the prior written consent of PepsiCo, Inc. and/or one of its affiliates (“PepsiCo”).  You further agree that you shall acquire no rights to any underlying intellectual property rights relating to or connected with the NFT, all of which are hereby reserved by PepsiCo.</p>
                <br></br>
                <button onClick={() => props.acceptTerms()} className={"green-agree-button"}>Accept</button>
            </div>
        </div>
    )
}

export default AcceptTermsModal;