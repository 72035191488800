import React, {useEffect, useRef} from "react";
import "./ChooseActivitySection.css";
import {useNavigate} from "react-router-dom";
//components
import ChooseActivityCard from './components/ChooseActivityCard.js'

const ChooseActivitySection = (props) => {
    if(props.appState.user && !props.appState.claimed && !props.appState.userNotInDatabase) {
        return (
            <div id="chooseActivitySection" className="choose-activity-section">
                <div className="choose-activity-section-header">
                    <h1>Choose your ideal activity</h1>
                    <h5>To unlock access to cool merch!</h5>
                </div>
                <div className="choose-activity-cards-container">
                    {props.appState.activities.map((activity, index) => {
                        return <ChooseActivityCard key={index} activity={activity} appState={props.appState}/>
                    })}
                </div>
            </div>
        );
    } else if(props.appState.user && props.appState.claimed){
        return (
            <div id="chooseActivitySection" className="choose-activity-section">
                <div className="choose-activity-section-header">
                    <h1>Merch already claimed!</h1>
                    <h5>Please allow 3-4 weeks for merch to arrive</h5>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default ChooseActivitySection;