import React from "react";
import './HomepageHeader.css';

//logos
import metamaskLogo from '../../images/MM_LOGO.png';
import diamondGraphic from '../../images/DiamondGraphic.png';

//bg images
import nftBackground from '../../images/nftBg.png';
import noNftBackground from '../../images/noNftBg.png';


const WelcomeText = (props) => {
    if(props.appState.user) {
        return (
            <h1 className='welcome-header-text'>
                {/*if user has first name then add it to the welcome text, otherwise render it the same as an unauthenticated user*/}
                Welcome{props.appState.shouldDisplayWelcomeBack ? ' back' : null}{props.appState.user.firstname ? `, ${props.appState.user.firstname}` : null }!
            </h1>
        );
    } else {
        return (
            <h1 className='welcome-header-text'>Welcome!</h1>
        );
    }

}

const ConnectYourWalletText = (props) => {
    if(!props.appState.accounts) {
        return (
            <>
                <h4 className='connect-to-immerse-text'>Thank you for attending our Web3Kickoff2022!</h4>
                <h4 style={{fontSize: '12px'}} className='connect-to-immerse-text'>The following reading materials are still available</h4>
            </>
        )
    } else {
        return null
    }
}

const ConnectMetaMaskSection = (props) => {

    // if user is connected to metamask
    if(props.appState.accounts) {

        // if user doesnt have 8/8 nft
        if(!props.appState.poap){
            return (

                <div className='connect-metamask-section'>
                        <div>
                            <img src={metamaskLogo} alt="metamask-logo" />
                        </div>
                        <div>
                            {/*<p>*/}
                            {/*    You don’t have this NFT in your wallet. Only 8/8 NFT holders can access this site.*/}
                            {/*</p>*/}

                            {/*<p>NFT not found</p>*/}
                            <p>Sorry, it looks like you don’t have this NFT in your wallet. Only Web3 Kickoff NFT holders can access this site.</p>
                            <p>If you believe this is an error please re-check your wallet or contact <a href="mailto:Brion.Goudreau@pepsico.com">Brion.Goudreau@pepsico.com</a></p>
                        </div>
                        <p className="metamask-address-text">{props.appState.accounts[0]}</p>
                </div>
            )
        } else {
            // if user has 8/8 nft
            if(props.appState.user && props.appState.checkedIn) {

                // user has NFT and checked in but their info is not in database
                if(props.appState.userNotInDatabase) {
                    return (
                        <div className="connect-metamask-section">
                            <div>
                                <img src={metamaskLogo} alt="metamask-logo"/>
                            </div>
                            <div>
                                <p>Sorry, your wallet address is not valid for merch claim.</p>
                            </div>
                            <p className="metamask-address-text">{props.appState.accounts[0]}</p>
                        </div>
                    )
                } else {
                    // user has NFT and checked in but their info is in database
                    return (
                        <div className="connect-metamask-section">
                            <div>
                                <img src={metamaskLogo} alt="metamask-logo"/>
                            </div>
                            <div>
                                <p>Connected to MetaMask and successfully checked in!</p>
                            </div>
                            <p className="metamask-address-text">{props.appState.accounts[0]}</p>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="connect-metamask-section">
                        <div>
                            <img src={metamaskLogo} alt="metamask-logo" />
                        </div>
                        <div>
                            <p>Sweet, we found the Web3 Kickoff NFT in your Wallet.</p>
                            <p>You will be prompted to sign a message using your MetaMask Wallet.</p>
                            <button onClick={() => props.doCheckIn()}>Claim your free gift!</button>
                        </div>
                    </div>
                );
            }
        }


    } else {
        return (
            <div className="connect-metamask-section">
        
                <div>
                    <img src={metamaskLogo} alt="metamask-logo" />
                </div>

                <div>

                    <button class= "disabled" onClick={() => props.startWalletConnection()}>This Event has ended!</button>
                    <p>Don't have a wallet? Use this <a href="/media/MetaMaskWalkthrough.pdf">guide</a> to create a MetaMask wallet</p>
                </div>
            </div>
        
        );
    }

}


const AccessMaterialsText = (props) => {
    return (
        <h4 className='connect-to-immerse-text'>Access Web3 materials</h4>
    )
}

const AccessConferenceMaterialSection = (props) => {
    return (
        <div className="access-conference-material-section">
            <div>
                <img src={diamondGraphic} alt="diamond-graphic" />
            </div>
            <div>
                <p>May Marketing Spotlight: <a target="_blank" href="https://pepsico.zoom.us/rec/play/D6wydbSdR9cLUCF7NDa1riVpJ1Icu9NiRuKIU_I5yBpWnSyPDktxmSX6OMl7u-prJf-NQ6kZZMB6EN7y.xrZGmgyCb4jGPBQ1?startTime=1653397215000">Web3</a></p>
                <p>Web3 Introduction: <a target="_blank" href="https://pepsico.sharepoint.com/:p:/r/teams/web30forpepsico-metaversecryptonftblockchain/Shared%20Documents/Web3%20101%205.3.22.pptx?d=wdb57e799147048779fcb8ffc09a7dd35&csf=1&web=1&e=efONVF">Web3 101</a></p>
                <p>Web3 Metaverse Kickoff Presentation (700mb): <a target="_blank" href="https://pepsico.sharepoint.com/:p:/r/teams/web30forpepsico-metaversecryptonftblockchain/Shared%20Documents/2022_PepsiCoLabs_Web3_Metaverse_Kickoff_Deck_Working_MASTER%20compressed.pptx?d=w9570ada2c2c743fb9e8b421a60ddd260&csf=1&web=1&e=XZDUCV">View</a></p>
            </div>
        </div>
    );
}



const HomepageHeader = (props) => {
    return (
        <div
            style={props.appState.poap ? { backgroundImage: `url(${nftBackground})` } : { backgroundImage: `url(${nftBackground})` }}
            className='homepage-header'
        >
            <div className='homepage-header-overlay'></div>
            <WelcomeText appState={props.appState} />
            <ConnectYourWalletText appState={props.appState} />
            <ConnectMetaMaskSection
                startWalletConnection={props.startWalletConnection}
                appState={props.appState}
                doCheckIn={props.doCheckIn}
            />
            <AccessMaterialsText />
            <AccessConferenceMaterialSection/>
        </div>
    )
};

export default HomepageHeader;