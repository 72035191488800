import {useEffect} from "react";
import './modal.css';
import web3KickoffNft from '../../images/web3kickoff-nft-image.jpeg';
import {useNavigate} from "react-router-dom";

const NotFoundModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const close = () => {
        props.toggleNotFoundModal(false);
        // navigate('/');
    }

    return(
        <div className='no-nft-modal-container'>
            <div className='modal'>
                <div className='modal-close-container'>
                    <p onClick={() => close()}>✖</p>
                </div>
                <img style={{marginBottom: '15px'}} src={web3KickoffNft} alt='cube' />
                <h3 className={"p-margin-bottom"}>Web3 Kickoff NFT not found</h3>
                <p className={"p-margin-bottom"}>Sorry, it looks like you don’t have this NFT in your wallet. Only Web3 Kickoff NFT holders can access this site.</p>
                <br></br>
                <p>If you believe this is an error please re-check your wallet or contact <a href="mailto:Brion.Goudreau@pepsico.com">Brion.Goudreau@pepsico.com</a></p>
                <button onClick={() => close()} className={"red-close-button"}>Close</button>
            </div>
        </div>
    )
}

export default NotFoundModal;