
import './modal.css';
import cubeImage from '../../images/CubeBlockImg.png';
import confetti from '../../images/confetti.gif';
import {useNavigate} from "react-router-dom";
const CongratsModal = (props) => {
    const navigate = useNavigate();
    const close = () => {
        props.toggleCongratsModal(false);
        navigate('/');
        setTimeout(() => {
            window.scrollTo(0, 7000);
        } , 100);
    }

    return(
        <div className='modal-container'>
            <div className='modal'>
                <div className='modal-close-container'>
                    <p onClick={() => close()}>✖</p>
                </div>
                <img src={cubeImage} alt='cube' />
                <h3>Congratulations!</h3>
                <p>Thank you for participating in PepsiCo’s Web3 Kick-off</p>
                <p className={"p-margin-bottom"}>We hope you enjoy your merch!</p>
                <br></br>
                <p className={"p-margin-bottom"}>You have now gone through: </p>
                <br></br>
                <p>1. Creating a digital wallet</p>
                <p>2. Getting an NFT airdropped to your wallet</p>
                <p className={"p-margin-bottom"}>3. Unlocking physical utility through your NFT</p>
                <br></br>
                <p>You will receive your merch within the next 3-4 weeks</p>
                <button className="back-to-home-button" onClick={() => close()}> Back to Home</button>
            </div>
        </div>
    )
}

export default CongratsModal;