import React from "react";
import './ChooseActivityCard.css';
import { useNavigate } from "react-router-dom";



const ChooseActivityCard = (props) => {
    const navigate = useNavigate();
    return (
        <div
            onClick={props.appState.claimClosed ? () => {alert('Web3 Kickoff has ended. You are no longer able to claim merch.')} : () => {navigate(`/product/${props.activity.id}`)} } // navigate to product page and pass productId/nftId as param
            className="choose-activity-card"
        >
            {props.appState.claimClosed && <div className="choose-activity-card-overlay"></div>}
            <div className="choose-activity-card-image-container">
                <img src={props.activity.image} alt={props.activity.name} />
            </div>

            <div className="choose-activity-card-text-container">
                <p>{props.activity.name}</p>
            </div>
        </div>
    )
}
export default ChooseActivityCard;